import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "../layout";

const CompaniesPage = React.lazy(() => import("company/pages/CompaniesPage"));
const CompanyPage = React.lazy(() => import("company/pages/CompanyPage"));

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

export default function Home() {
  return (
    <StateProvider>
      <MainLayout>
        <chakra.div>
          <Routes>
            <Route
              index
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <h1>HOME</h1>
                </React.Suspense>
              }
            />

            <Route
              path="/companies"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <CompaniesPage />
                </React.Suspense>
              }
            />
            <Route
              path="/company/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <CompanyPage />
                </React.Suspense>
              }
            />
          </Routes>
        </chakra.div>
      </MainLayout>
    </StateProvider>
  );
}
