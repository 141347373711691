import { useCallback, useEffect, useState } from "react";
import constate from "constate";
import { useLocalStorage } from "./useLocalStorage";
import { getUserAggregateProfile } from "proto/aggregate/v1/aggregate_api-AggregateAPI_connectquery";
import { useQuery } from "@connectrpc/connect-query";
import { Timestamp } from "@bufbuild/protobuf";

const useAggProfile = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getUserAggregateProfile,
    {}
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } = useAggProfile();

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
