// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { Company } from "../../company/v1/company_pb.js";

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileRequest
 */
export const GetUserAggregateProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileRequest",
  [],
);

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileResponse
 */
export const GetUserAggregateProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "company", kind: "message", T: Company },
  ],
);

