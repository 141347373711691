// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/user.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Media } from "./media_pb.js";
import { RoleType } from "./role_pb.js";

/**
 * @generated from message base.v1.User
 */
export const User = /*@__PURE__*/ proto3.makeMessageType(
  "base.v1.User",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mobile", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "avatar", kind: "message", T: Media },
    { no: 8, name: "role_type", kind: "enum", T: proto3.getEnumType(RoleType) },
  ],
);

