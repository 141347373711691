// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/access.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.AccessType
 */
export const AccessType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.AccessType",
  [
    {no: 0, name: "ACCESS_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "ACCESS_TYPE_OWNER", localName: "OWNER"},
    {no: 2, name: "ACCESS_TYPE_MANAGER", localName: "MANAGER"},
    {no: 3, name: "ACCESS_TYPE_SUPERVISOR", localName: "SUPERVISOR"},
  ],
);

