// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { UploadMedia } from "../../base/v1/media_pb.js";
import { RoleType } from "../../base/v1/role_pb.js";

/**
 * @generated from message user.v1.GetProfileRequest
 */
export const GetProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetProfileRequest",
  [],
);

/**
 * @generated from message user.v1.GetProfileResponse
 */
export const GetProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileRequest
 */
export const UpdateProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdateProfileRequest",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatar", kind: "message", T: UploadMedia },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileResponse
 */
export const UpdateProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdateProfileResponse",
  [],
);

/**
 * @generated from message user.v1.ListAllUsersRequest
 */
export const ListAllUsersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.ListAllUsersRequest",
  () => [
    { no: 1, name: "roles", kind: "enum", T: proto3.getEnumType(RoleType), repeated: true },
  ],
);

/**
 * @generated from message user.v1.ListAllUsersResponse
 */
export const ListAllUsersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.ListAllUsersResponse",
  () => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message user.v1.RequestAccountDeleteRequest
 */
export const RequestAccountDeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.RequestAccountDeleteRequest",
  [],
);

/**
 * @generated from message user.v1.RequestAccountDeleteResponse
 */
export const RequestAccountDeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.RequestAccountDeleteResponse",
  [],
);

